import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import { formatDate } from "../../constants/helpers";
import apiServiceActions from "../../services/apiServiceActions";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Zoom from "@mui/material/Zoom";
import Modal from "@mui/material/Modal";
import { deepOrange, red, green, blue } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import LoadingData from "../../components/Loaders/Loading";
import { Tab } from "@mui/material";
import TransactionById from "../../components/TransactionByUserId/TransactionById";

const API_BASE_URL = process.env.REACT_APP_GOCHAPAA_API_ENDPOINT;

const UserDetails = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const { userId } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("Fetching user details for userId:", userId);
    const fetchUserDetails = async () => {
      try {
        const userDetails = await apiServiceFetchers.getUserById(
          userId,
          currentUser.token
        );
        console.log("Fetched user details:", userDetails);
        setUserDetails(userDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [userId, currentUser.token]);

  const fetchUpdatedKycDocuments = async () => {
    try {
      const updatedUserDetails = await apiServiceFetchers.getUserById(
        userId,
        currentUser.token
      );
      console.log("FETCHED USER DETAILS", updatedUserDetails);
      setUserDetails(updatedUserDetails);
    } catch (error) {
      console.error("Failed to fetch updated KYC documents", error);
    }
  };

  const handleApproveKyc = async () => {
    try {
      await apiServiceActions.approveKyc(userId, currentUser.token);
      setSnackbarMessage("KYC approved successfully!");
      setUserDetails({ ...userDetails, verificationStatus: "verified" });
      setSnackbarOpen(true);
      fetchUpdatedKycDocuments();
    } catch (error) {
      setSnackbarMessage("Failed to approve KYC");
      setSnackbarOpen(true);
    }
  };

  const handleRejectKyc = async () => {
    try {
      await apiServiceActions.rejectKyc(userId, currentUser.token);
      setSnackbarMessage("KYC rejected successfully!");
      setUserDetails({ ...userDetails, verificationStatus: "unverified" });
      setSnackbarOpen(true);
      fetchUpdatedKycDocuments();
    } catch (error) {
      setSnackbarMessage("Failed to reject KYC");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const setColorForStatus = (status) => {
    switch (status) {
      case "verified":
        return green[500];
      case "unverified":
        return red[500];
      case true:
        return green[500];
      case false:
        return red[500];
      default:
        return blue[500];
    }
  };

  const setIconForStatus = (status) => {
    switch (status) {
      case "verified":
        return <VerifiedIcon color="success" />;
      case "unverified":
        return <GppMaybeIcon sx={{ color: red[500] }} />;
      case true:
        return <VerifiedIcon color="success" />;
      case false:
        return <GppMaybeIcon sx={{ color: red[500] }} />;
      default:
        return <GppMaybeIcon sx={{ color: red[500] }} />;
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoadingData />
        </Grid>
      </Box>
    );
  }

  if (!userDetails) {
    return <Box>Error loading user details...</Box>;
  }

  // Remove duplicates based on filePath and originalName
  const uniqueDocuments = userDetails.kycDocuments.filter(
    (doc, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.filePath === doc.filePath && t.originalName === doc.originalName
      )
  );
  console.log(`${API_BASE_URL} and ${document.filePath}`);

  return (
    <ThemeProvider theme={theme}>
      <Box padding={2} sx={{ overflowX: "auto" }}>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{
                padding: 3,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.07)"
                    : "whitesmoke",
                height: "auto",
              }}
            >
              <Stack spacing={2} paddingBottom={2}>
                <Typography
                  variant="h6"
                  component="h2"
                  fontWeight={"bold"}
                  sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                >
                  User Profile
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                >
                  Joined: {formatDate(userDetails.createdAt)}
                </Typography>
              </Stack>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    sx={{ bgcolor: deepOrange[500], width: 56, height: 56 }}
                    alt={userDetails.firstName}
                    src={
                      userDetails.profilePictures
                        ? userDetails.profilePictures[0]
                        : ""
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                  >
                    {userDetails.firstName} {userDetails.lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={2} paddingTop={2}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                    onClick={handleApproveKyc}
                  >
                    Approve Kyc
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                    onClick={handleRejectKyc}
                  >
                    Reject Kyc
                  </Button>
                </Stack>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                >
                  {userDetails.phone}
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card
              sx={{
                padding: 3,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.07)"
                    : "whitesmoke",
                height: 290,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                marginBottom={2}
                fontWeight={"bold"}
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                User Info
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>Email:</strong> {userDetails.email}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>DOB:</strong>{" "}
                {userDetails.dateOfBirth
                  ? formatDate(userDetails.dateOfBirth)
                  : "-"}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                marginBottom={2}
                sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                marginTop={2}
                fontWeight={"bold"}
              >
                Verification
              </Typography>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  style={{ marginRight: "8px" }}
                >
                  <strong>Account Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.verificationStatus),
                  }}
                >
                  {setIconForStatus(userDetails.verificationStatus)}
                </Typography>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  component="p"
                  style={{ marginRight: "8px" }}
                >
                  <strong>Phone Number Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.isPhoneVerified),
                  }}
                >
                  {setIconForStatus(userDetails.isPhoneVerified)}
                </Typography>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  style={{ marginRight: "8px" }}
                >
                  <strong>Email Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.isEmailVerified),
                  }}
                >
                  {setIconForStatus(userDetails.isEmailVerified)}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* The tabs */}
        <ThemeProvider theme={theme}>
          <Card sx={{ marginTop: 3 }}>
            <Grid container>
              {/* TRANSACTIONS */}
              <Grid item xs={6}>
                <Tab
                  label="Transactions"
                  onClick={() => handleChange(null, 0)}
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    padding: "16px",
                    borderBottom:
                      value === 0
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              </Grid>
              {/* KYC DOCUMENTS */}
              <Grid item xs={6}>
                <Tab
                  label="KYC Documents"
                  onClick={() => handleChange(null, 1)}
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    padding: "16px",
                    borderBottom:
                      value === 1
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <Box
            flex="1"
            overflow="auto"
            sx={{
              color: theme.palette.text.default,
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {value === 0 && (
              <TransactionById
                sx={{
                  color: theme.palette.text.default,
                  fontWeight: "bold",
                  width: "100%",
                }}
              />
            )}
            {value === 1 && (
              <Grid item xs={12}>
                <Card>
                  {uniqueDocuments.length > 0 ? (
                    <ImageList
                      variant="masonry"
                      cols={3}
                      gap={10}
                      sx={{ padding: 1 }}
                    >
                      {uniqueDocuments.map((document) => {
                        if (document.filePath) {
                          const imageUrl = `${API_BASE_URL}${document.filePath}`;
                          return (
                            <Zoom in={true} key={document.id}>
                              <ImageListItem>
                                <ImageListItemBar
                                  title={document.type}
                                  subtitle={`Uploaded on: ${new Date(
                                    document.createdAt
                                  ).toLocaleDateString()}`}
                                  position="top"
                                  style={{ marginBottom: 80 }}
                                />
                                <img
                                  src={imageUrl}
                                  alt={document.type}
                                  loading="lazy"
                                  style={{
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: 250,
                                  }}
                                  onClick={() => handleImageClick(imageUrl)}
                                  onError={(e) => {
                                    e.target.src =
                                      "path/to/placeholder-image.jpg"; // Set a placeholder image on error
                                  }}
                                />
                              </ImageListItem>
                            </Zoom>
                          );
                        }
                        return null;
                      })}
                    </ImageList>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      textAlign="center"
                      padding={1}
                    >
                      No KYC documents found
                    </Typography>
                  )}
                </Card>
              </Grid>
            )}
          </Box>
        </ThemeProvider>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={!!selectedImage}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "90%",
              maxHeight: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <img
              src={selectedImage}
              alt="Full size"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default UserDetails;
